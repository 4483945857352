<!-- 问答分类>>>新增分类||编辑 -->
<template>
  <div class="qaCate-container">
    <el-drawer
      :title="
        type == 'add'
          ? level == 1
            ? '新增一级分类'
            : '新增子分类'
          : '编辑分类'
      "
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="formRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="分类名称" prop="typeName">
          <el-input
            class="common-screen-input_50"
            v-model="ruleForm.typeName"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="resetForm" size="mini">取消</el-button>
          <el-button type="primary" @click="submitForm" size="mini"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { addFirst, addSecond, editCate } from "../../../service/auto";

export default {
  name: "",
  components: {},
  props: {
    drawer: Boolean,
    shopId: Number
  },
  data() {
    return {
      ruleForm: {
        typeName: "", //	分类名称
        shopId: 0,
        mainId: 0, //	父级id
        id: 0 //	分类Id
      },
      rules: {
        typeName: [
          { required: true, message: "请输入活动名称", trigger: "blur" }
        ]
      },
      type: "",
      level: 1
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getInfo(params, row) {
      if (params.type == "add") {
        if (params.level == 1) {
          this.ruleForm = {
            typeName: "",
            shopId: this.shopId
          };
        } else {
          this.ruleForm = {
            typeName: "",
            shopId: this.shopId,
            mainId: params.id
          };
        }
      } else {
        this.ruleForm = {
          typeName: row.typeName,
          shopId: this.shopId,
          id: params.id,
          mainId: params.level
        };
      }
      this.level = params.level;
      this.type = params.type;
      if (this.$refs["formRef"]) {
        this.$refs["formRef"].resetFields();
      }
    },
    submitForm() {
      let params = { ...this.ruleForm };
      console.log(params, "+++++");
      this.$refs["formRef"].validate(async (valid) => {
        if (valid) {
          //   请求

          if (this.level == 1) {
            const res = await addFirst(params);
            if (res.code !== 200) return this.$message.error("创建失败！");
            this.$message.success("新增成功！");
          } else if (this.level == 2) {
            const res = await addSecond(params);
            if (res.code !== 200) return this.$message.error("创建失败！");
            this.$message.success("新增成功！");
          } else {
            const res = await editCate(params, this.ruleForm.id);
            if (res.code !== 200) return this.$message.error("修改失败！");
            this.$message.success("修改成功！");
          }
          this.$emit("handleClose");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["formRef"].resetFields();
      this.$emit("handleClose");
    },
    handleClose(done) {
      this.$emit("handleClose", done);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped></style>
